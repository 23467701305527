import {ChangeEvent, MouseEvent, ReactElement, ReactNode} from 'react';
import dayjs from 'dayjs';
import {GLOBAL_CONSTS} from 'utils/const';
import Consts from './MyProjectsConsts';

const {
  COMPLETED,
  IN_PROGRESS,
  IN_REVIEW,
  CANCELLED,
  DISPUTED,
  PREPROCESSING,
  POSTPROCESSING,
  HOLD,
  READY,
  PENDING,
  PENDING_STATUS,
  IN_PROGRESS_STATUS,
  HOLD_STATUS,
  IN_REVIEW_STATUS,
  READY_STATUS,
  CANCELLED_STATUS,
  COMPLETED_STATUS,
  DISPUTED_STATUS,
  PREPROCESSING_STATUS,
  POSTPROCESSING_STATUS,
  REGULAR_STATUS_COLOR,
  PREPROCESSING_STATUS_COLOR,
  POSTPROCESSING_STATUS_COLOR,
  IN_PROGRESS_STATUS_COLOR,
  READY_STATUS_COLOR,
  IN_REVIEW_STATUS_COLOR,
  COMPLETED_STATUS_COLOR,
} = Consts;

const {SECONDS_IN_DAY, SECONDS_IN_HOUR, SECONDS_IN_MINUTE, HOURS_IN_DAY} = GLOBAL_CONSTS;

export interface ProjectProps {
  batchId: string | null;
  child: ExpandRowProps;
  created: number;
  dueTime: number | null;
  estimation: number;
  expiration: number;
  expertise: SimpleCodeNameProps;
  id: number;
  idPrefix: string;
  languagePair: LanguagePairProps;
  name: string | null;
  rate: number | null;
  status: string;
  tags: TagsProps[];
  type: string;
  volume: VolumeProps;
  showComments: boolean;
  showSla: boolean;
  isDelayed: boolean;
}

export interface ExpandRowProps {
  id: number;
  idPrefix: string;
  name: string | null;
  type: string;
  languagePair: LanguagePairProps;
  created: number;
  volume: VolumeProps;
  dueTime: number | null;
  expiration: number | boolean;
  expertise: SimpleCodeNameProps;
  child: any;
  rate: number | null;
  showComments: boolean;
  status: string;
  tags: TagsProps[];
  isDelayed: boolean;
}

export interface ModalProps {
  openModal: boolean;
  setOpenModal: (openFilterModal: boolean) => void;
}

export interface FilterModalProps extends ModalProps {
  filterLabels: FilterLabelsProps[];
  setFilterLabels: (filterLabels: FilterLabelsProps[]) => void;
  filterState: FilterStateProps;
  dispatchFilterState: (filterState: FilterDataProps) => void;
  setDataFromFilterModal: (dataFromFilterModal: FilterDataProps) => void;
  dateFrom: string;
  dateTo: string;
}

export interface TagsModalProps extends ModalProps {
  projectId?: number;
  handleDeleteTag: (tag_id: string | number) => ((event: ChangeEvent<unknown>) => void) | undefined;
  tagModalData: TagModalDataProps[];
  setTagModalData: (tagModalData: TagModalDataProps[]) => void;
  modalTags: TagsProps[];
  setModalTags: (modalTags: ((prevState: TagsProps[]) => TagsProps[]) | TagsProps[]) => void;
  selectedValues?: number[];
}

export interface SelectedProjectsTagsProps {
  id: number;
  tags: string[];
}

export interface FilterProps {
  [key: string]: string | string[] | null;
}

export interface MultipleFieldProps {
  value: string;
  label: string;
  placeholder: string;
}

interface LanguagePairProps {
  source: SimpleCodeNameProps;
  target: SimpleCodeNameProps;
}

interface VolumeProps {
  unit: string;
  count: number;
}

interface SimpleCodeNameProps {
  id: number | null;
  name: string | null;
}

export interface TagsProps {
  id: number | string;
  name: string;
}

export interface TagModalDataProps {
  id: number;
  tags: TagsProps[];
}

export interface FilterLabelsProps {
  label: string;
  value: string[] | null;
  state_value: string;
}

export interface FilterDataProps {
  [key: string]: string | string[] | number | number[] | null;
}

export interface FilterStateProps {
  CSRFToken?: string;
  dateFrom: string | null;
  dateTo: string;
  ids: string[];
  names: string[];
  statuses: string[];
  tags: string[];
  sourceLanguages: number[];
  targetLanguages: number[];
  expertises: number[];
  sort: SortTypes;
  order: OrderTypes;
  page: number;
  limit: number;
  allTime: number | null;
}

export type OrderTypes = 'asc' | 'desc';

export type SortTypes = 'id' | 'type' | 'status' | 'expertise';

export interface DownloadModalProps {
  setDownloadModal: (value: boolean) => void;
  projectDownloadEstimation: number;
  selectedCount: number;
  selectedValues: number[];
  isDownloadModal: boolean;
  translatedProjects: {id: number; status: string}[];
}

export interface RatingProps {
  id: number;
  rate: number | null;
  haveSubProject?: boolean;
  setOpenRateModal: (openRateModal: boolean) => void;
  setRateId: (rateId: number | null) => void;
}

export interface SelectedProjectsProps {
  id: number;
  status: string;
  rate: number | null;
}

export interface TableProps {
  projectsLoading: boolean;
  projectsList: ProjectsListProps[];
  sortBy: string;
  sortDirection: string;
  selected: number[];
  subSelected: number[];
  setSelected: (values: number[]) => void;
  setSubSelected: (values: number[]) => void;
  dispatchFilterState: (state: {order: string; sort: string}) => void;
  filterState: FilterStateProps;
  setProjectsLoading: (projectsLoading: boolean) => void;
  setDataFromFilterModal: (dataFromFilterModal: FilterDataProps) => void;
  isFiltered: boolean;
  tagModalData: TagModalDataProps[];
  setTagModalData: (tagModalData: TagModalDataProps[]) => void;
  userProjectsTotal: number;
  setOpenRateModal: (openRateModal: boolean) => void;
  setRateId: (rateId: number | null) => void;
  handleProjectRename: (groupId: number, name: string, isTitle: boolean, id?: number) => void;
}

export interface ProjectsListProps {
  groupId: number;
  title: string;
  overallProjectsCount: number;
  projects: ProjectProps[];
  groupStatus: string;
}

export interface TableBodyRowProps {
  groupId: number;
  groupTitle: string;
  overallProjectsCount: number;
  projects: ProjectProps[];
  groupStatus: string;
  selected: number[];
  subSelected: number[];
  setSelected: (value: number[]) => void;
  setSubSelected: (value: number[]) => void;
  tagModalData: TagModalDataProps[];
  setTagModalData: (tagModalData: TagModalDataProps[]) => void;
  filterState: FilterStateProps;
  setOpenRateModal: (openRateModal: boolean) => void;
  setRateId: (rateId: number | null) => void;
  handleProjectRename: (groupId: number, name: string, isTitle: boolean, id?: number) => void;
}

export interface ColorsProps {
  [key: string]: {
    color: string;
    bgc: string;
  };
}

export interface TableSubRowProps {
  child: ExpandRowProps[];
  props: {
    groupId: number;
    subSelected: number[];
    getProjectStatus: (value: string) => ReactNode;
    getEstimate: (value: number | undefined) => ReactNode;
    handleSubClick: (event: MouseEvent<HTMLElement>, id: number, idx: number) => void;
    handleStopBubbling: (event: MouseEvent<HTMLElement>) => void;
    projectTagsView: (tag: TagsProps, tags_count: number | boolean, id: number) => ReactNode;
    handleAddTagClick: (id: number) => void;
    tagModalData: TagModalDataProps[];
    redirectToProjectPage: (projectLink: string) => void;
    setOpenRateModal: (openRateModal: boolean) => void;
    setRateId: (rateId: number | null) => void;
  };
  handleRowRename: (newName: string, id?: number, isTitle?: boolean) => void;
}

export const getFullDays = (value: number) => Math.floor(value / SECONDS_IN_DAY);

export const getFullHours = (value: number) => Math.floor(value / SECONDS_IN_HOUR) % HOURS_IN_DAY;

export const getFullMinutes = (value: number) => Math.floor(value / SECONDS_IN_MINUTE) % SECONDS_IN_MINUTE;

export const getProjectDate = (value: number) => dayjs(value).format('ddd, DD MMM YYYY');

export interface EmptyStateTableProps {
  column: ReactElement;
  tooltip?: ReactElement;
  tooltipNote?: string;
}

export interface InitialBadMoodStateProps {
  trans_unsatisfactory_service: boolean;
  trans_overly_literal: boolean;
  trans_not_followed_instrctns: boolean;
  trans_spell_tps_grmr_mistakes: boolean;
  trans_delivery_not_on_time: boolean;
}

export interface ProjectNameProps {
  new_project_name: string;
  project_id: string;
  action: string;
}

export interface GroupNameProps {
  id: number;
  title: string;
}

export const text = {
  [PENDING]: PENDING_STATUS,
  [IN_PROGRESS]: IN_PROGRESS_STATUS,
  [HOLD]: HOLD_STATUS,
  [READY]: READY_STATUS,
  [IN_REVIEW]: IN_REVIEW_STATUS,
  [CANCELLED]: CANCELLED_STATUS,
  [COMPLETED]: COMPLETED_STATUS,
  [DISPUTED]: DISPUTED_STATUS,
  [PREPROCESSING]: PREPROCESSING_STATUS,
  [POSTPROCESSING]: POSTPROCESSING_STATUS,
};

export const colors: ColorsProps = {
  [PENDING]: REGULAR_STATUS_COLOR,
  [IN_PROGRESS]: IN_PROGRESS_STATUS_COLOR,
  [HOLD]: REGULAR_STATUS_COLOR,
  [READY]: READY_STATUS_COLOR,
  [IN_REVIEW]: IN_REVIEW_STATUS_COLOR,
  [CANCELLED]: REGULAR_STATUS_COLOR,
  [COMPLETED]: COMPLETED_STATUS_COLOR,
  [DISPUTED]: REGULAR_STATUS_COLOR,
  [PREPROCESSING]: PREPROCESSING_STATUS_COLOR,
  [POSTPROCESSING]: POSTPROCESSING_STATUS_COLOR,
};

export const rowsPerPageOptions: number[] = [10, 25, 50];

export interface IBulkMessagePayload {
  text: string;
  projects: number[];
}
