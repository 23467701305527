import React, {lazy} from 'react';
import {observer} from 'mobx-react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import HomeLayout from './components/HomeLayout/HomeLayout';
import AdminBlendTalk from 'pages/BlendTalk/components/AdminBlendTalk/AdminBlendTalk';
import ProjectsPage from 'pages/LmDashboard/components/ProjectsPage';
import LmDashboardRoot from 'pages/LmDashboard/components/Dashboard';
import Consts from 'utils/globalConstants';

const {INCORRECT_USER_TEXT} = Consts;

const Authorization = lazy(() => import('pages/Authorization/AuthorizationView'));
const Registration = lazy(() => import('pages/Registration/RegistrationView'));
const ResetPassword = lazy(() => import('pages/ResetPassword/ResetPasswordView'));
const CreatePassword = lazy(() => import('pages_refactor/CreatePassword/CreatePasswordView'));
const ControlPanel = lazy(() => import('pages_refactor/ControlPanel/ControlPanelView'));
const Affiliate = lazy(() => import('pages_refactor/AffiliatePage/AffiliatePageView'));
const Revenues = lazy(() => import('pages/AffiliateRevenues/AffiliateRevenuesView'));
const CustomerStatement = lazy(() => import('pages/CustomerStatement/CustomerStatementView'));
const Wizard = lazy(() => import('pages/Wizard/WizardView'));
const Editor = lazy(() => import('pages/Editor/EditorView'));
const Invoices = lazy(() => import('pages/Invoices/InvoicesView'));
const MessageBox = lazy(() => import('pages/MessageBox'));
const Profile = lazy(() => import('pages/Profile/Profile'));
const ClientAlerts = lazy(() => import('pages/ClientAlerts/ClientAlertsView'));
const LmDashboard = lazy(() => import('pages/LmDashboard/LmDashboardView'));
const Resource = lazy(() => import('pages/ResourceView'));
const Payment = lazy(() => import('pages/Payment/PaymentView'));
const ShoppingCart = lazy(() => import('pages/ShoppingCart/ShoppingCartView'));
const NotFoundView = lazy(() => import('pages_refactor/NotFound/NotFoundView'));
const VoicesPage = lazy(() => import('pages_refactor/VoicesPage/VoicesPageView'));
const MyProjects = lazy(() => import('pages_refactor/MyProjects/MyProjectsView'));
const TranslatorMyProjects = lazy(() => import('pages_refactor/TranslatorMyProjects/TranslatorMyProjectsView'));
const AvailableProjects = lazy(() => import('pages_refactor/AvailableProjects/AvailableProjectsView'));
const Backoffice = lazy(() => import('pages/Backoffice/Backoffice'));
const Dashboard = lazy(() => import('pages/Statistics/StatisticsView'));
const Balance = lazy(() => import('pages/Balance/BalanceView'));
const Statement = lazy(() => import('pages/Statement/StatementView'));
const DemoProject = lazy(() => import('pages/ProjectPage/DemoProject/DemoProjectView'));
const BlendTalk = lazy(() => import('pages/BlendTalk/BlendTalkView'));
const WizardBrief = lazy(() => import('pages/Wizard/components/StepBrief/StepBrief'));
const Convert = lazy(() => import('pages/Convert/ConvertView'));
const PaymentQuoteView = lazy(() => import('pages_refactor/PaymentQuote/PaymentQuoteView'));
const MyTeamView = lazy(() => import('pages_refactor/MyTeam/MyTeamPageView'));
const MyProjectsViewWrapper = lazy(() => import('pages_refactor/MyProjectsViewWrapper/MyProjectsViewWrapper'));

const Router = observer(() => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/resource-view/:uuid/" element={<Resource />} />
      <Route path="/wizard/2/*" element={<Wizard />} />
      <Route path="/brief" element={<WizardBrief />} />
      <Route path="/voices" element={<VoicesPage />} />
      <Route path="/auth/register" element={<Registration />} />
      <Route path="/auth/resetPassword" element={<ResetPassword />} />
      <Route path="/auth/createPassword" element={<CreatePassword />} />
      <Route path="/auth2" element={<Authorization locationState={location.state} />} />
      <Route path="/cpanel" element={<ControlPanel />} />
      <Route path="/payments" element={<Payment />} />
      <Route path="/payment/quote" element={<PaymentQuoteView />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/profile/show" element={<Navigate to="/profile-legacy/show/" replace />} />
      <Route path="/ui-editor" element={<Editor />} />
      <Route path="/my-projects" element={<MyProjectsViewWrapper />}>
        <Route index element={<MyProjectsViewWrapper />} />
        <Route path="customer" element={<MyProjects />} />
        <Route path=":type" element={<TranslatorMyProjects />} />
      </Route>
      <Route path="/open-project-freelance-translator/*" element={<AvailableProjects />} />
      <Route path="/partner" element={<Revenues />} />
      <Route path="/shopping-cart" element={<ShoppingCart />} />
      <Route path="/blend-talk/chat/" element={<BlendTalk />} />
      <Route path="/blend-talk/chat/:id" element={<BlendTalk />} />
      <Route path="/mbox" element={<MessageBox />} />
      <Route path="/member/invoices" element={<Invoices />} />
      <Route path="/customer-statement" element={<CustomerStatement />} />
      <Route path="/statement" element={<Statement />} />
      <Route path="/client-alerts" element={<ClientAlerts />} />
      <Route path="/lm_dashboard" element={<LmDashboard />}>
        <Route index element={<LmDashboardRoot />} />
        <Route path=":type/projects" element={<ProjectsPage />} />
      </Route>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/convert" element={<Convert />} />
      <Route path="/balance" element={<Balance />} />
      <Route path="/affiliate-program" element={<Affiliate />} />
      <Route path="/project/demo" element={<DemoProject />} />
      <Route path="/admin/blend-talk/user/:userId/chat/" element={<AdminBlendTalk />} />
      <Route path="/admin/blend-talk/user/:userId/chat/:id" element={<AdminBlendTalk />} />
      <Route path="/admin/*" element={<Backoffice />}>
        <Route path=":id" element={<Backoffice />} />
      </Route>
      <Route path="/voice" element={<VoicesPage />} />
      <Route path="/my-team" element={<MyTeamView />} />
      <Route path="/incorrect-account" element={<NotFoundView text={INCORRECT_USER_TEXT} />} />
      <Route path="/" element={<HomeLayout />} />
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
});

export default Router;
