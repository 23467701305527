import queryString from 'query-string';

import core from './core';
import {
  addLanguagePairParams,
  PostProjectNoteParams,
  PostSMSVerificationParams,
  PostTFACodeParams,
  PostUpdateApiKeysParams,
  PostUpdateBillingInfoParams,
  PostUpdateNetworksInfoParams,
  PostUpdatePasswordParams,
  removeLanguagePairParams,
} from 'pages/Profile/types';

// TODO remove this bad variant of using API, normal in getProfileApi
export function getProfileInfo(flow: string | null) {
  let query = 'api/2/profilewizard';
  if (flow) {
    query += '?flow=' + flow;
  }
  return core.get(query); //.then(({ data }) => data?.results);
}

export function postUpdateUser(params: any): any {
  return core.post(
    'userService/updateAccountInfo',
    queryString.stringify(params, {
      skipNull: true,
    })
  );
}

export function postStartVerify(phone: string) {
  return core.post('ajax/phoneverify/startverify', queryString.stringify({phone}));
}

export function getProfileApi() {
  return core.get('api/2/profilewizard').then(({data}) => data?.results);
}

export function postTryVerify(phone: string, code: string) {
  return core.post('ajax/phoneverify/tryverify', queryString.stringify({phone, code}));
}

export function getCheckVerify() {
  return core.get('ajax/phoneverify/status').then(({data}) => data?.results?.status);
}

export function getCheckSubscription() {
  return core.get('telegram/subscription').then(({data}) => data?.results?.has_subscribed);
}

export function postSubscription(isSubscribed: number) {
  return core.post('telegram/subscription', queryString.stringify({has_subscribed: isSubscribed}));
}

export function postTelegramSMS() {
  return core.post('telegram/sms');
}

export function postBillingInfo(params: PostUpdateBillingInfoParams) {
  return core.post(
    'userService/updateBillingInfo',
    queryString.stringify(params, {
      skipNull: true,
    })
  );
}

export function postApiKeysData(params: PostUpdateApiKeysParams) {
  return core.post(
    'userService/updateApiKeysForm',
    queryString.stringify(
      {...params, action: 'keygen'},
      {
        skipNull: true,
      }
    )
  );
}

export function resetApiToken() {
  return core.get('userService/updateApiKeysForm?s=reset&react=1');
}

export function generateApiKeys(token: string) {
  return core.post(
    'userService/updateApiKeysForm',
    queryString.stringify(
      {token, token_api: '1'},
      {
        skipNull: true,
      }
    )
  );
}

export function generateClientKeys(redirect_uri: string, CSRFToken: string) {
  return core.post(
    'api/2/oauth/generate-keys',
    queryString.stringify(
      {redirect_uri, CSRFToken},
      {
        skipNull: true,
      }
    )
  );
}

export function updateClientDetails(redirect_uri: string, CSRFToken: string) {
  return core.post(
    'api/2/oauth/update-client',
    queryString.stringify(
      {redirect_uri, CSRFToken},
      {
        skipNull: true,
      }
    )
  );
}

export function postNetworksInfo(params: PostUpdateNetworksInfoParams) {
  return core.post(
    'userService/updateNetworksInfo',
    queryString.stringify(params, {
      skipNull: true,
    })
  );
}

export function addLanguagePairToUserSkills(params: addLanguagePairParams) {
  return core.get(
    `service/addLanguagePairToUserSkills?uid=${params.uid}&source_id=${params.source_id}&target_id=${params.target_id}&native_id=${params.native_id}`
  );
}

export function removeLanguagePairFromUserSkills(params: removeLanguagePairParams) {
  return core.get(
    `service/removeLanguagePairFromUserSkills?uid=${params.uid}&source_id=${params.source_id}&target_id=${params.target_id}`
  );
}

export function setTranscriptionSkill(uid: string, lang: string) {
  return core.post('userService/setTranscriptionSkill', queryString.stringify({uid, lang}));
}

export function removeTranscriptionSkill(uid: string, lang: string) {
  return core.post('userService/setTranscriptionSkill', queryString.stringify({uid, lang, status: 'cancel'}));
}

export function postLanguageInfo(nativeLan: string, nativeLan2: string) {
  return core.post('userService/addLanguageInfo', queryString.stringify({nativeLan, nativeLan2}));
}

export function addExpertiseToUser(uid: string, exp_id: string) {
  return core.get(`service/addExpertiseToUser?uid=${uid}&exp_id=${exp_id}`);
}

export function removeExpertiseForUser(uid: string, exp_id: string) {
  return core.get(`service/removeExpertiseForUser?uid=${uid}&exp_id=${exp_id}`);
}

export function checkExpertOnlyFlag(isExpert: boolean) {
  return core.post('userService/checkExpertOnlyFlag', queryString.stringify({is_only_expert: isExpert ? 1 : 0}));
}

export function uploadCV({fd}: any): Promise<any> {
  return core.post('service/uploadCV', fd);
}

export function uploadGovID({fd}: any): Promise<any> {
  return core.post('service/uploadGovID', fd);
}

export function uploadCertification({fd}: any): Promise<any> {
  return core.post('service/uploadCertification', fd);
}

export function uploadAvatar({fd}: any): Promise<any> {
  return core.post('service/uploadImage', fd);
}

export function postAboutMeInfo(about: string): Promise<any> {
  return core.post('userService/addAboutMeInfo', queryString.stringify({about}));
}

export function addAvailabilityInfo({fd}: any): Promise<any> {
  return core.post('userService/addAvailabilityInfo', fd);
}

export function addRefereesInfo({fd}: any): Promise<any> {
  return core.post('userService/addRefereesInfo', fd);
}

export function sendSMSVerification(params: PostSMSVerificationParams) {
  return core.post(
    'userService/sendSMSVerification',
    queryString.stringify(params, {
      skipNull: true,
    })
  );
}

export function saveSoftwareService({fd}: any) {
  return core.post('userService/saveSoftwareService', fd);
}

export function updateMailingPreferences({fd}: any) {
  return core.post('userService/updateMailingPreferences', fd);
}

export function addSecEmailToUser(uid: string, email: string) {
  return core.post('userService/addSecEmailToUser', queryString.stringify({uid, email}));
}

export function removeSecEmailFromUser(uid: string, email: string) {
  return core.post('userService/removeSecEmailFromUser', queryString.stringify({uid, email}));
}

export function getPreferredTranslators(CSRFToken: string | undefined) {
  return core.get('api/2/account/customer/preferred-translators?CSRFToken=' + CSRFToken).then(({data}) => data);
}

export function setTranslatorGuidelineAgreement(value: boolean, CSRFToken: string | undefined) {
  return core.post('/api/2/accept-terms-of-service?CSRFToken=' + CSRFToken, queryString.stringify({value}));
}

export function getIndustry() {
  return core.get('/userService/getIndustry');
}

export function getExpertise() {
  return core.get('userService/getExpertise');
}

export function getTimeZones() {
  return core.get('userService/getTimeZones').then(({data}) => data);
}

export function getSoftware() {
  return core.get('userService/getSoftware').then(({data}) => data);
}

export function getServices() {
  return core.get('userService/getServices').then(({data}) => data);
}

export function getXTMLanguages() {
  return core.get('tmx/getxtmlanguages').then(({data}) => data);
}

export function getTMXFiles() {
  return core.get('tmx/getTMX').then(({data}) => data);
}

export function uploadTMXFiles({fd}: any): Promise<any> {
  return core.post('tmx/import', fd);
}

export function getTerminologyLanguages(CSRFToken: string | undefined) {
  return core.get('api/2/discover/languages?CSRFToken=' + CSRFToken).then(({data}) => data);
}

export function getTerminologyFiles() {
  return core.get('terminology/list').then(({data}) => data);
}

export function checkTerminology(source: string, target: string) {
  return core.get(`terminology?srcLang=${source}&tarLang=${target}`).then(({data}) => data);
}

export function uploadTerminologyFiles({fd}: any): Promise<any> {
  return core.post('terminology/import', fd).then(({data}) => data);
}

export function deleteTerminologyFile(id: string): Promise<any> {
  return core.delete('terminology/delete?ids[]=' + id).then(({data}) => data);
}

export function updatePassword(params: PostUpdatePasswordParams) {
  return core.post(
    'userService/updatePassword',
    queryString.stringify(params, {
      skipNull: true,
    })
  );
}

export const getBeneficiaryAccounts = (CSRFToken: string | undefined) =>
  core.get('api/2/account/beneficiary?CSRFToken=' + CSRFToken).then(({data}) => data);

export function switchBeneficiaryAccount(CSRFToken: string | undefined, uid: string) {
  return core.post(`api/2/account/switchBA`, queryString.stringify({CSRFToken, uid}));
}

export function saveAutomaticFunds(CSRFToken: string | undefined, uuid: string, value: boolean) {
  return core.post(
    `api/2/account/beneficiary/${uuid}/settings/auto_transfer?CSRFToken=${CSRFToken}`,
    queryString.stringify({value})
  );
}

export function addBeneficiaryAccount(CSRFToken: string | undefined, email: string) {
  return core.post(`api/2/account/beneficiary?CSRFToken=${CSRFToken}`, queryString.stringify({email}));
}

export function deleteBeneficiaryAccount(CSRFToken: string | undefined, uuid: string) {
  return core.delete(`api/2/account/beneficiary/${uuid}?CSRFToken=${CSRFToken}`);
}

export const allocateCredits = (CSRFToken: string | undefined, uuid: string, credits: number) =>
  core.post(
    `api/2/account/balance/transfer?CSRFToken=${CSRFToken}`,
    queryString.stringify({to_user_uuid: uuid, credits})
  );

export const getProfileBalanceHistory = (bearerToken: string, since: string) =>
  core.get(`api/2/account/balance/history?since=${since}`, {
    headers: {
      authorization: `Bearer ${bearerToken}`,
    },
  });

export function getProjectNotesList(CSRFToken: string | undefined) {
  return core.get('userService/getProjectNotesList?CSRFToken=' + CSRFToken).then(({data}) => data);
}

export function deleteProjectNote(CSRFToken: string | undefined, id: string) {
  return core.get(`userService/deleteProjectNote?id=${id}&CSRFToken=${CSRFToken}`).then(({data}) => data);
}

export function saveProjectNote(params: PostProjectNoteParams) {
  return core.post(
    'userService/saveProjectNote',
    queryString.stringify(params, {
      skipNull: true,
    })
  );
}

export function getGlossaryFilesList(uid: string) {
  return core.get(`api/2/account/${uid}/glossary`).then(({data}) => data);
}

export function uploadGlossaryFile({fd, uuid}: any): Promise<any> {
  return core.post(`api/2/account/${uuid}/glossary`, fd).then(({data}) => data);
}

export function deleteGlossaryFile(uuid: string, id: string, CSRFToken: string): Promise<any> {
  return core.delete(`api/2/account/${uuid}/glossary/${id}?CSRFToken=${CSRFToken}`).then(({data}) => data);
}

export function getTFACode(CSRFToken: string | undefined) {
  return core.get('api/2/auth/2fa?CSRFToken=' + CSRFToken).then(({data}) => data);
}

export function sendTFACode(params: PostTFACodeParams) {
  return core.post(
    'api/2/account/2fa',
    queryString.stringify(params, {
      skipNull: true,
    })
  );
}

export function resetTFACode(CSRFToken: string | undefined, code: string) {
  return core.post(
    'api/2/account/2fa/reset',
    queryString.stringify(
      {CSRFToken, code},
      {
        skipNull: true,
      }
    )
  );
}

export function getDeleteReasons() {
  return core.get('userService/getDeleteReasons').then(({data}) => data);
}

export function checkCredentials(email: string, password: string) {
  return core.post(
    'api/2/account/check-credentials',
    queryString.stringify(
      {email, password},
      {
        skipNull: true,
      }
    )
  );
}

export function deleteAccount(reason: string) {
  return core.post('ajax/deleteAccount', JSON.stringify({reason: reason}));
}

export const getBlendTalkFeature = (CSRFToken: string, uuid?: string) =>
  core
    .get(`/api/2/site/ui-feature/config/get?uuid=${uuid}&CSRFToken=${CSRFToken}`)
    .then(({data}) => !!data?.results?.status && !!data?.results?.config?.enabled);

export const checkPolicyTerms = (CSRFToken: string) =>
  core.get('/api/2/user/checkPolicyTermsConditions?CSRFToken=' + CSRFToken);

export const updatePolicyTerms = (CSRFToken: string) =>
  core.get('/api/2/user/updatePolicyTermsConditions?CSRFToken=' + CSRFToken);

export const getAccountInfoById = (userId: string) =>
  core.get(`/api/2/account?user_id=${userId}`).then(({data}) => data?.results);
