import React, {MouseEvent, useCallback} from 'react';
import styled from 'styled-components';
// TODO need to remove bootstrap and after use material
import {Nav, NavDropdown} from 'react-bootstrap';
import {CustomNavDropdown} from './NavDropdown';
import useMobile from 'hooks/useMobile';
import useConfig from 'hooks/useConfig';
import {useAuth, useUser} from 'context/UserData';
import core from 'api/core';

const {Item} = NavDropdown;
const {Link} = Nav;

interface NavGenerator {
  (adminNavItems: string): Array<any>;
}

const NAV_MENU_TYPES = {
  LINK: 'link',
  DROPDOWN: 'dropdown',
};

interface ZendeskParams {
  return_to: string;
  jwt: string;
}

interface ZendeskResponse {
  zendeskAuthUrl: string;
  authParams: ZendeskParams;
}

const supportParams = {
  type: NAV_MENU_TYPES.LINK,
  data: {
    href: 'https://help.getblend.com/hc/en-us/requests/new',
    title: 'Support',
  },
  action: () => {
    core
      .get<ZendeskResponse>('/api/2/zendesk/auth-params')
      .then(({data}) => {
        const url = data?.zendeskAuthUrl;

        const formData = new FormData();

        Object.entries(data?.authParams || {}).forEach(([key, value]) => {
          value && formData.append(key, value);
        });

        url &&
          core
            .post(url, formData)
            .catch((error) => {
              console.error(error);
            })
            .finally(() => window.open('https://help.getblend.com/hc/en-us/requests/new', '_blank'));
      })
      .catch(() => {
        window.open('https://help.getblend.com/hc/en-us/requests/new', '_blank');
      });
  },
};

const adminNavItems = () => [
  {
    type: NAV_MENU_TYPES.LINK,
    data: {href: '/cpanel/', title: 'Dashboard'},
  },
  {
    type: NAV_MENU_TYPES.LINK,
    data: {href: '/my-projects/customer/', title: 'Projects'},
  },
  {
    type: NAV_MENU_TYPES.LINK,
    data: {href: '/myteam', title: 'MyTeam'},
  },
  supportParams,
];

const unloggedNavItems = (blendDomainURL: string) => [
  {
    type: NAV_MENU_TYPES.LINK,
    data: {
      href: `https://${blendDomainURL}/about-us`,
      title: 'About',
    },
  },
  {
    type: NAV_MENU_TYPES.DROPDOWN,
    data: {
      title: 'Translators',
      links: [
        {
          href: 'https://www.onehourtranslation.com/translation/about/human-translator',
          title: 'Join as a Translator',
        },
        {
          href: `https://help.${blendDomainURL.replace('www.', '')}`,
          title: 'Translators FAQ',
        },
      ],
    },
  },
  supportParams,
];

const translatorNavItems = () => [
  {
    type: NAV_MENU_TYPES.LINK,
    data: {
      href: '/dashboard',
      title: 'Dashboard',
    },
  },
  {
    type: NAV_MENU_TYPES.LINK,
    data: {
      href: '/my-projects/translator/',
      title: 'Projects',
    },
  },
  {
    type: NAV_MENU_TYPES.LINK,
    data: {
      href: '/open-project-freelance-translator',
      title: 'Open Projects',
    },
  },
  supportParams,
];

const clientNavItems = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {userData} = useUser();
  const myTeamStatus = userData?.myTeamStatus;

  const navItems = [
    {
      type: NAV_MENU_TYPES.LINK,
      data: {
        href: '/cpanel',
        title: 'Dashboard',
      },
    },
    {
      type: NAV_MENU_TYPES.LINK,
      data: {
        href: '/my-projects/customer/',
        title: 'Projects',
      },
    },
    myTeamStatus
      ? {
          type: NAV_MENU_TYPES.LINK,
          data: {
            href: '/myteam',
            title: 'MyTeam',
          },
        }
      : null,
    supportParams,
    {
      type: NAV_MENU_TYPES.LINK,
      data: {
        href: '/voice',
        title: 'Voices',
      },
    },
  ];
  return navItems.filter((item) => !!item);
};

const CustomNav = ({navItemsGenerator}: {navItemsGenerator: NavGenerator}) => {
  const {isMobile} = useMobile();
  const {config} = useConfig();
  const navItems = navItemsGenerator(config?.blendDomainURL || '');
  const {handleLogout} = useAuth();
  const {
    userData,
    adminSubmarine: {openAdminActions, setSubLocation},
  } = useUser();
  const {isAdmin} = userData || {};

  const openAdminClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      setSubLocation(event.clientX, event.clientY);
      openAdminActions();
    },
    [openAdminActions, setSubLocation]
  );

  const handleLogoutClick = useCallback(() => {
    const {CSRFToken} = config;
    handleLogout({CSRFToken});
  }, [config, handleLogout]);

  return (
    <Wrapper className={`${isMobile ? 'mobile' : 'desktop'}-nav`}>
      {navItems.map(
        ({type, data: {title, href, links}, action}) =>
          (type === NAV_MENU_TYPES.LINK && (
            <StyledLink
              key={title}
              href={href}
              target={['Support'].includes(title) ? '_blank' : '_self'}
              onClick={action}
            >
              {title}
            </StyledLink>
          )) ||
          (type === NAV_MENU_TYPES.DROPDOWN && (
            <CustomNavDropdown title={title} key={title} id="nav-dropdown">
              {links &&
                links.map(({title: linkTitle, href: linkHref}: any) => (
                  <Item key={linkTitle} href={linkHref} onTouchStart={(e: any) => e.stopPropagation()}>
                    {linkTitle}
                  </Item>
                ))}
            </CustomNavDropdown>
          ))
      )}
      {isMobile && isAdmin && <StyledLink onClick={openAdminClick}>Open admin actions</StyledLink>}
      {isMobile && <StyledLink onClick={handleLogoutClick}>logout</StyledLink>}
    </Wrapper>
  );
};

const Wrapper = styled(Nav)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledLink = styled(Link)`
  color: ${({theme}) => theme.colors.grey000};
  font-family: 'Atten New';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  padding: 8px 16px;
  box-sizing: border-box;

  :hover {
    color: rgb(138, 136, 255);
  }
`;

export const NavAdmin = (props: any) => <CustomNav {...props} navItemsGenerator={adminNavItems} />;
export const NavUnlogged = () => <CustomNav navItemsGenerator={unloggedNavItems} />;
export const NavTranslator = () => <CustomNav navItemsGenerator={translatorNavItems} />;
export const NavClient = () => <CustomNav navItemsGenerator={clientNavItems} />;
