import {ApiResponse} from 'pages/Wizard/types';
import core from './core';
import {IUserDetails} from '../components/GeneralComponents/AdminActions/useUserAction';
import axios from 'axios';

export type AdminTab = {
  label: string;
  loc: string;
};

export type BackofficeData = {
  tabs: Array<AdminTab>;
  components: Array<string>;
};

export interface ICountryData {
  admin_locale: string;
}

export type ApiTabResponse = ApiResponse<BackofficeData>;

export function postSearchProject({fd}: any) {
  return core.post('/adminService/isProjectExist', fd).then(({data}) => data);
}

export function postSearchUser({fd, legacyUrl}: any) {
  return core.post(`/${legacyUrl}admin/float`, fd).then(({data}) => data);
}

export function getUserToAct(id: string) {
  return core.get(`/manage/act?NoRedirect=1&uid=${id}`);
}

export function restoreAdmin() {
  return core.get(`/manage/act?NoRedirect=1`);
}

export function getUserDetails(id: string): Promise<{data: IUserDetails}> {
  return core.get(`/adminService/getUserDescription?uid=${id}`);
}

export function changeCurrency(currency: string, token: string) {
  return core.get(`/api/2/tools/${currency}/SetCurrency?CSRFToken=${token}`);
}

export const getCountriesList = () => core.get(`/api/2/country/list`);

export const setAdminCountry = (data: ICountryData) =>
  axios({
    method: 'POST',
    url: `/api/2/admin/country/set-locale`,
    data,
    headers: {'Content-Type': 'application/json'},
  }).then(({data: responseData}) => responseData);

export const resetAdminCountry = () => core.post(`/api/2/admin/country/reset-locale`).then(({data}) => data);

export function getAdminTabs() {
  return core.get<ApiTabResponse>(`/api/2/admin/backoffice`);
}
